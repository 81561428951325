// TODO: find a good way to store this text (csv? arrays?).
// The <br> tags do not allow for much customization (scss styling etc).

export const bigIdea =
`Hello. 👋🏽`

export const smallerIdea = `
I'm a Waterloo ECE grad living in New York! 🗽\n
I enjoy building and scaling distributed systems and programming with low-level languages like Go and Rust.\n
Some of my interests include basketball 🏀, chess ♖, food 🌭, and fitness 🏋️.
`
